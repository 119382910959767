import React from 'react';
import Card from './Card';
import u1 from '../../../../assets/u1.png';
import u2 from '../../../../assets/u2.png';
import u3 from '../../../../assets/u3.png';
// import google from '../../../../assets/nuborrowwallet.png';
import { motion } from 'framer-motion';
import { textanimation } from '../Animation/index';

// import RadioButton from './RadioComponent/index';

type Props = {
  setopenComponent: React.Dispatch<React.SetStateAction<number>>;
};

// const index = ({ setopenComponent }: Props) => {
function index() {
  return (
    <>
      <div className="container bg-white w-full py-20 px-4 sm:px-0 overflow-x-hidden">
        <div className="">
          <motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }} >
            <p className="font-raleway text-3xl lg:text-5xl xl:text-7xl text-center font-black  ">
              Join the movement
            </p>
          </motion.div>
        </div>
        <div className="flex flex-col md:flex-row gap-2 justify-between">
          <div className="m-auto md:w-[45%] xl:w-3/5">
            <motion.p
             variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }} 
              className="text-brand text-base md:text-2xl font-medium font-raleway py-4"
            >
              Clients give Nuborrow 98% happiness rating!
            </motion.p>
            <motion.p
          variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}
          
              className="font-medium text-2xl lg:text-5xl lg:font-extrabold font-raleway py-4"
            >
              The (almost) 5-Star mortgage company.
            </motion.p>
            <motion.p
              variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }} 
              className="text-[#969696] text-base md:text-xl font-medium font-raleway pt-5"
            >
              We are so proud of our team and love client feedback, but don't take our word for it.
              Read the reviews or watch the video testimonials, to see what our clients have to say.
            </motion.p>
          </div>
          <div className="m-auto md:w-[45%] xl:w-2/5">
            <Card
              Name="Tracy Weiler"
              image={u1}
              content=" We had a great experience working with Rob. He responded to all our questions quickly and efficiently. He is very friendly, helpful, knowledgeable and professional through the whole process. Highly recommended!"
            />
            <Card
              Name="Larry Pearce"
              image={u2}
              content="From the moment I applied on line, I received my call the next day from a friendly and knowledgeable person. He worked with us through the process of securing the mortgage to purchase the home we already live in."
            />
            <Card
              Name="Jamie Andre"
              image={u3}
              content="This was the most refreshing financial experience I have had thus far in my years of owning multiple properties. Nuborrow took the time to cater options that suited my needs and walked me through how to not only help mine and my families financial health but also learn how to use the equity in my home to my benefit."
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
