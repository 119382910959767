import React, { useState } from 'react';

type Props = {
  content: string;
  id: any;
  setCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setCheck2: React.Dispatch<React.SetStateAction<boolean>>;
  setCheck3: React.Dispatch<React.SetStateAction<boolean>>;
  setCheck4: React.Dispatch<React.SetStateAction<boolean>>;
  path: any
  check: boolean;
  setGoal: React.Dispatch<React.SetStateAction<string>>;
  setopenComponent: React.Dispatch<React.SetStateAction<number>>;

  // setCreditScore: React.Dispatch<React.SetStateAction<string>>;
  // setDisable: React.Dispatch<React.SetStateAction<boolean>>
  // n: number;
};

function index({ content, id, check, setCheck, setCheck2, setCheck3, setCheck4, setGoal ,setopenComponent,path}: Props) {


  // const [errorbox, setErrorbox] = useState('');


  const checkValidity = (check: Boolean) => {
    // check ? setopenComponent(3) : setErrorbox('Select answer first');
    path==3? setopenComponent(3):path==6? setopenComponent(6):null;
  };
  return (
    <>

      <div
        className={`flex flex-row
        ${check ? 'bg-brand text-white' : 'bg-white text-gray-400'} 
         rounded-full my-2  px-6 w-11/12 sm:w-3/4 md:w-1/2  border-2  justify-center items-center hover:border-brand`}
      >
        <input
        onClick={()=>{checkValidity(check)
          setCheck(id == 1 ? true : false);
          setCheck2(id == 2 ? true : false);
          setCheck3(id == 3 ? true : false);
          setCheck4(id == 4 ? true : false);
          setGoal(content);
          document.getElementById("view")?.scrollIntoView()}}
          id={id}
          type="radio"
          name="bordered-radio"
          className="accent-brand hidden outline-none rounded-full font-sans text-xl pl-4 sm:px-16 py-4"
          // onChange={() => {
          //   setCheck(id == 1 ? true : false);
          //   setCheck2(id == 2 ? true : false);
          //   setCheck3(id == 3 ? true : false);
          //   setCheck4(id == 4 ? true : false);
          //   setGoal(content);
          // }}
        />
        <label
        htmlFor={id} className=" cursor-pointer px-4 py-4 sm:px-12 ml-2 w-full text-sm ">
          {content}
        </label>
      </div>

    </>
  );
}

export default index;
