import React, { useState } from 'react';
import home from '../../../assets/home.png';
import { motion } from 'framer-motion';
import { textanimation } from './Animation/index';

type Props = {
  setopenComponent: React.Dispatch<React.SetStateAction<number>>;
  setProduct: React.Dispatch<React.SetStateAction<string>>;
  product: string;
};

function FourPageCompoOne({ setopenComponent, setProduct, product }: Props) {
  // Error****
  const [proerror, setProError] = useState('');
  // const[clickedID,setClickedID]=useState<number>()

  return (
    <>
      <div className="pt-20">
        <motion.div
          variants={textanimation}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
        >
          <p className="font-raleway mx-2 sm:mx-0 text-3xl  md:text-4xl lg:text-5xl xl:text-7xl text-center font-black" >
          Get <span className='text-brand'>Approved</span>  online today!
          </p>
        </motion.div>
        <motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          <p className="font-sans text-[#737373] font-bold text-sm sm:text-base md:text-xl lg:text-2xl xl:text-[32px] text-center mt-10 mx-2">
            Get your free credit score, property value and detailed mortgage plan - all at no cost.
          </p>
        </motion.div>
      </div>
      <div className="flex flex-col justify-center items-center mt-10">
        <div className="flex gap-1 sm:gap-0 flex-row w-full lg:w-[80%] flex-wrap justify-center items-center ">
          <div className="flex flex-col mt-2 sm:mt-0  md:basis-1/2 lg:basis-1/3 justify-center items-center md:justify-between">
            <div className="w-20 h-20 md:w-40 md:h-40">
              <img src={home} alt="" className="" />
            </div>
            <button 
            // id='page2_btn_HEL'
              onClick={() => {
                setProduct('Home equity loan');
                setopenComponent(2);
                document.getElementById("view")?.scrollIntoView()
              }}
              className="bg-brand select-none rounded-xl font-sans text-xs md:text-lg font-semibold text-white px-3 sm:px-10 pt-2 pb-2  mt-8 hover:bg-white hover:text-brand border-brand border-2"
            >
              Home equity loan
            </button>
          </div>
          <div className="flex flex-col mt-2 sm:mt-0  md:basis-1/2 lg:basis-1/3 justify-center items-center md:justify-between">
            <div className="w-20 h-20 md:w-40 md:h-40">
              <img src={home} alt="" className="" />
            </div>
            <button
            // id='page2_btn_HELOC'
              onClick={() => {
                setProduct('Home equity line');
                setopenComponent(2);
                document.getElementById("view")?.scrollIntoView()
              }}
              className="bg-brand select-none rounded-xl font-sans text-xs md:text-lg font-semibold text-white px-3 sm:px-10 pt-2 pb-2  mt-8 hover:bg-white hover:text-brand border-brand border-2"
            >
              Home equity line
            </button>
          </div>
          <div className="flex flex-col mt-2 sm:mt-0 md:basis-1/2 lg:basis-1/3 justify-center items-center md:justify-between">
            <div className="w-20 h-20 md:w-40 md:h-40">
              <img src={home} alt="" className="" />
            </div>
            <button
            // id='page2_renew'
              onClick={() => {
                setProduct('Renew my mortgage');
                setopenComponent(2);
                document.getElementById("view")?.scrollIntoView()
              }}
              className="bg-brand select-none rounded-xl font-sans text-xs md:text-lg font-semibold text-white px-3 sm:px-10 pt-2 pb-2  mt-8 hover:bg-white hover:text-brand border-brand border-2"
            >
              Renew my mortgage
            </button>
          </div>
        </div>
        <div className="mt-5 mb-20 flex flex-col">
          {/* <button
            onClick={() => {
              !product?setProError("Choose product first"):setopenComponent(2);
            }}
            className="bg-brand rounded-full font-sans text-lg font-bold text-white pl-5 pr-5 pt-3 pb-3  sm:pl-10 sm:pr-10 sm:pt-4 sm:pb-4 mt-8 hover:bg-white hover:text-brand hover:border-brand hover:border-2"
          >
            Let’s build your free reports
          </button> */}
          <span className="text-red-700 mx-auto text-center sm:w-[65%] my-4 text-[10px] sm:text-[12px] font-semibold">
            {proerror}
          </span>
        </div>
      </div>
    </>
  );
}

export default FourPageCompoOne;
